import React from "react"
import { StaticQuery, graphql } from "gatsby"
import { Row, Col } from "react-bootstrap"

import Image from "react-bootstrap/Image"

const ContentPage = ({ className = "" }) => (
  <StaticQuery
    query={graphql`
      {
        allFile(filter: { relativeDirectory: { eq: "qohelet/content2" } }) {
          edges {
            node {
              publicURL
              childImageSharp {
                fixed(quality: 80, height: 300) {
                  src
                }
              }
            }
          }
          totalCount
        }
      }
    `}
    render={data => (
      <div className={`px-5 ${className}`}>
        <Row className="no-gutters">
          <Col>
            <h3 className="text-left">GLI IMPIANTI</h3>
            <hr />
            <p>
              La principale attività di Qohelet Solar Italia S.p.A, rivolta alla
              promozione e costruzione di centrali fotovoltaiche, si è
              fortemente sviluppata nel corso degli anni attraverso la
              realizzazione di circa 20 MW di impianti, collaborando con società
              di grande prestigio sia per la elaborazione dei progetti quanto
              per le autorizzazioni e costruzione degli stessi.Alcuni dei nostri
              principali lavori “chiavi in mano”:
            </p>
          </Col>
        </Row>
        <div>
          {data.allFile.edges.map(({ node }, index) => (
            <Row key={index}>
              <Col md={1}></Col>
              <Col>
                <div>
                  <a
                    href={node.publicURL}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Image src={node.publicURL} fluid />
                  </a>
                </div>
              </Col>
              <Col md={1}></Col>
            </Row>
          ))}
        </div>
      </div>
    )}
  />
)

export default ContentPage
