import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layouts/layout"
import SEO from "../components/seo"
import NavBack from "../components/layouts/navBackGruppoSc"

import Header1FluidLogoWhite from "../components/contents/header1FluidLogoWhite"

import IndexContent from "../content/qohelet/index"

const QoheletPage = ({ data, location }) => (
  /* console.log(data.placeholderImage2.childImageSharp.fluid.src), */

  <Layout
    location={location}
    header={
      <Header1FluidLogoWhite
        src_sfondo={data.placeholderImage2.childImageSharp.fluid}
        data_img={data.placeholderImage.childImageSharp.fixed}
        title="Qohelet"
        className="a1"
      />
    }
  >
    <SEO title="Qohelet" />

    <IndexContent />

    <NavBack />
  </Layout>
)

export const query = graphql`
  {
    placeholderImage: file(relativePath: { eq: "loghi/qohelet.jpg" }) {
      childImageSharp {
        fixed(width: 400, jpegQuality: 80) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    placeholderImage2: file(relativePath: { regex: "/qohelet/header/" }) {
      childImageSharp {
        fluid(quality: 80, maxWidth: 1200) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    site {
      siteMetadata {
        author
        description
        title
      }
    }
  }
`

export default QoheletPage
