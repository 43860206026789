import React from "react"
import { Container } from "react-bootstrap"

import Content1 from "./content1"
import Content2 from "./content2"
import Content3 from "./content3"
import Content5 from "./content5"
import Content4 from "./content4"

// import { Link } from "gatsby"

const IndexContentPage = () => (
  <>
    <Container>
      <Content1 />
      <Content2 className="mt-1" />
      <Content3 className="mt-3" />
      <Content5 className="mt-3" />
    </Container>

    <hr />
    <Container className="mt-3">
      <div className={`px-5 `}>
            <p className="_02 paragraph">Alcuni dei nostri principali lavori “chiavi in mano”:</p>
      </div>
    </Container>
    <Container fluid className="mt-4">
      <Content4 />
    </Container>



    {/* <hr></hr>
    <Container>
      <h3 className="text-left">Progetti</h3>
      <ul>
        <li>
          <Link to="/projects/qhoelet/biofeedstock"> Biofeedstock </Link>
        </li>
      </ul>
      
    </Container> */}
  </>
)

export default IndexContentPage
