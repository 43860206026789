import React from "react"

import { Row, Col } from "react-bootstrap"

const ContentPage = ({ className = "" }) => (
  <div className={`p-5 ${className}`}>
    <Row className="no-gutters">
      <Col>
        <h3 className="text-left">Presentazione</h3>
        <hr />
        <p className="_02 paragraph">
          QOHELET SOLAR ITALIA SpA, è una società che si occupa della
          realizzazione di centrali di produzione di energia elettrica da fonte
          solare. Nasce nel dicembre del 2008 dall’incontro tra due società:
          Qohelet Solar S.A. e Archimede S.r.l. <br /> <br />
          Dal 2013 la partecipazione societaria è detenuta dalla società
          Archimede Srl e dalla società Ing. Pavesi &amp; Co. Spa. <br /> <br />
          La sede legale della società si trova a Caltanissetta, nel cuore della
          Sicilia. E’ da qui che guardiamo ai mercati emergenti con grande
          interesse poiché la nostra mission è quella di creare sviluppo e di
          garantire redditività degli investimenti ai nostri clienti che ci
          danno fiducia. <br /> <br />
          Qohelet Solar Italia Spa ha realizzato fin qui 20 MWp di impianti in
          Sicilia ed è titolare di autorizzazzioni per la realizzazione di
          impianti FV per ulteriori 46 MWp, di cui: <br /> <br />- 25 MWp in
          Italia; <br />- 21 MWp in Romania. <br /> <br />
          La progettazione di impianti solari FV chiavi in mano è stato il
          principale fattore distintivo di Qohelet Solar Italia sin dalla sua
          nascita. Know-how tecnico di livello ed esperienza specifica acquisita
          nella progettazione di sistemi fotovoltaici hanno contribuito a
          contraddistinguere la nostra azienda. <br /> <br />
          “Progettare” significa ideare, concepire e dar forma a un impianto
          curandone ogni minimo particolare e con un unico obiettivo: ogni
          sistema fotovoltaico è il frutto di un sapiente lavoro di squadra che
          vede coinvolto tutto lo staff tecnico nelle fasi di progettazione,
          iter autorizzativo, gestione cantiere, installazione e logistica.{" "}
          <br /> <br />
          Professionalità, competenza, esperienza ed interdisciplinarità sono i
          punti di forza del team qohelet, grazie ai quali è in grado di seguire
          il cliente dalla fase di progettazione a quella di realizzazione,
          gestione e manutenzione dell’impianto fotovoltaico.Inoltre, grazie ad
          accordi vincenti con società leader nel settore delle rinnovabili
          Qohelet Solar Italia è in grado di far fronte alle vostre richieste e
          necessità più diverse, accompagnandovi durante tutto l’iter che vi
          farà diventare “Produttori di Energia Pulita”.
        </p>
      </Col>
    </Row>
  </div>
)

export default ContentPage
