import React from "react"
import { StaticQuery, graphql } from "gatsby"

import BannerCarousel from "../../components/htmls/BannerCarousel"

const ContentPage = ({ className = "" }) => (
  <StaticQuery
    query={graphql`
      {
        allFile(filter: { relativeDirectory: { eq: "qohelet/Carousel1" } }) {
          edges {
            node {
              publicURL
              childImageSharp {
                fluid(quality: 80, maxWidth: 240) {
                  ...GatsbyImageSharpFluid
                  src
                }
              }
            }
          }
          totalCount
        }
      }
    `}
    render={data => (
      <div className={`${className}`}>
        {/* <BannerCarousel images={data.allFile.edges} paths={pathLoghi} /> */}
        <BannerCarousel images={data.allFile.edges} isModal={true} />
      </div>
    )}
  />
)

export default ContentPage
