import React from "react"

import { Row, Col } from "react-bootstrap"

import { Link } from "gatsby"

const ContentPage = ({ className = "" }) => (
  <div className={`px-5 ${className}`}>
    <Row className="no-gutters">
      <Col>
        <h3 className="text-left">Progetti</h3>
        <hr />
        
        <h4> - <Link to="/projects/qhoelet/biofeedstock"> Biofeedstock </Link></h4>
        <div className="_02 paragraph pl-4 " >
            <p>
                La Società Qohelet Solar Italia SPA ha condotto studi inerenti l’identificazione di biomasse residuali di potenziale interesse e la mappatura dei relativi bacini di potenziale approvigionamento.
            </p>
           
        </div>
        <br />
        <br />
      </Col>
    </Row>
  </div>
)

export default ContentPage
